<template>
    <div class="MiniBanner">
        <div class="search" style="display: block">
            <div class="search-group">
                <span class="search-label">标题</span>
                <a-input v-model="sTitle" class="search-style" placeholder="标题"/>
            </div>

            <div class="search-group">
                <span class="search-label">类型</span>
                <a-select
                        class="search-style"
                        @change="_changeCategory"
                        v-model="sCategory"
                        placeholder="类型"
                >
                    <a-select-option value="">
                        全部
                    </a-select-option>
                    <a-select-option value="h5">
                        活动h5页面
                    </a-select-option>
                    <a-select-option value="mini">
                        小程序
                    </a-select-option>
                    <a-select-option value="img">
                        图片
                    </a-select-option>
                </a-select>
            </div>
            <div class="search-group">
                <span class="search-label">分类</span>
                <a-select
                        class="search-style"
                        @change="_changeLabel"
                        v-model="sLabel"
                        placeholder="分类"
                >
                    <a-select-option value="">
                        全部
                    </a-select-option>
                    <a-select-option
                            v-if="categoryList"
                            v-for="item in categoryList"
                            :value="item.Id"
                    >{{ item.title }}
                    </a-select-option
                    >
                </a-select>
            </div>
            <div class="search-group">
                <span class="search-label">状态</span>
                <a-select
                        class="search-style"
                        placeholder="状态"
                        v-model="sStatus"
                        @change="_status"
                >
                    <a-select-option value="">
                        全部
                    </a-select-option>
                    <a-select-option value="1">
                        显示
                    </a-select-option>
                    <a-select-option value="2">
                        隐藏
                    </a-select-option>
                </a-select>
            </div>
            <div class="search-group">
                <span class="search-label">时间范围</span>
                <a-range-picker
                        class="search-style"
                        style="width: 200px"
                        format="YYYY-MM-DD"
                        :placeholder="['开始时间', '结束时间']"
                        allowClear
                        @change="ChangeSearchTime"
                />
            </div>

            <a-button class="search-style" style="margin-right: 15px" @click="_clear"
            >重置
            </a-button
            >
            <a-button type="primary" @click="_search">查询</a-button>
            <a-button type="primary" style="float: right" @click="_action()"
            >添加
            </a-button
            >
        </div>
        <a-table v-if="data" :pagination="false" :data-source="data" bordered>
            <a-table-column key="Title" title="标题" data-index="Title"/>
            <a-table-column key="LabelName" title="分类" data-index="LabelName"/>
            <a-table-column key="Category" title="类型" data-index="Category"/>
            <a-table-column key="Status" title="状态" data-index="Status">
                <template slot-scope="text, record">
                    <span>{{ record.Status == 1 ? '显示' : '隐藏' }}</span>
                </template>
            </a-table-column>
            <a-table-column key="VisitNum" title="访问次数" data-index="VisitNum"/>
            <a-table-column key="StartDate" title="开始时间" data-index="StartDate"/>
            <a-table-column key="EndDate" title="结束时间" data-index="EndDate"/>
            <a-table-column key="Icon" title="图标">
                <template slot-scope="text, record">
                    <div v-viewer>
                        <img
                                style="width: 24px;cursor: pointer"
                                v-if="record.Icon"
                                :src="record.Icon"
                                alt=""
                        />
                    </div>
                </template>
            </a-table-column>
            <a-table-column key="BannerImg" title="banner">
                <template slot-scope="text, record">
                    <div v-viewer>
                        <img
                                style="width: 24px;cursor: pointer"
                                v-if="record.BannerImg"
                                :src="record.BannerImg"
                                alt=""
                        />
                    </div>
                </template>
            </a-table-column>
            <a-table-column key="Range" title="权限范围" data-index="Range">
                <template slot-scope="text, record">
                    <span v-if="text == 1">会员</span>
                    <span v-else-if="text == 2">非会员</span>
                    <span v-else>不限</span>
                </template>
            </a-table-column>
            <a-table-column key="IsCheck" title="会员绑定" data-index="IsCheck">
                <template slot-scope="text, record">
                    <span v-if="text == 1">是</span>
                    <span v-else>否</span>
                </template>
            </a-table-column>

            <a-table-column key="Sort" title="排序" data-index="Sort"/>
            <a-table-column key="Remark" title="备注" data-index="Remark"/>
            <a-table-column key="Id" title="操作">
                <template slot-scope="text, record">
                    <i
                            class="iconfont icon-bianji icon-btn"
                            style="font-size: 20px"
                            @click.stop="_action(text, record.Id)"
                    ></i
                    >&nbsp;&nbsp;
                    <a-popconfirm
                            title="您确定要删除吗?"
                            @confirm="_del(record.Id)"
                            okText="确定"
                            cancelText="取消"
                    >
                        <i class="iconfont icon-shanchu- icon-btn"></i>
                    </a-popconfirm>
                </template>
            </a-table-column>
        </a-table>
        <a-pagination
                v-if="total"
                style="margin-top: 35px;text-align: right;"
                v-model="Page"
                @change="ChangePage"
                :pageSize="Limit"
                :total="total"
                show-less-items
        />

        <a-drawer
                :title="Id ? '修改' : '添加'"
                width="600"
                :closable="false"
                :visible="visible"
                @close="onClose"
        >
            <div style="margin-bottom: 60px">
                <div class="row">
                    <span class="row-title">标题</span>
                    <a-input class="row-input" v-model="Title" placeholder="标题"/>
                </div>
                <div class="row">
                    <span class="row-title">分类</span>

                    <a-select
                            v-show="LabelId"
                            class="row-input"
                            v-model="LabelId"
                            placeholder="分类"
                            @change="handleChange"
                    >
                        <a-select-option
                                v-if="categoryList"
                                v-for="item in categoryList"
                                :value="item.Id"
                        >{{ item.title }}
                        </a-select-option
                        >
                    </a-select>
                    <a-select
                            v-show="!LabelId"
                            class="row-input"
                            v-model="LabelId"
                            placeholder="分类"
                            @change="handleChange"
                    >
                        <a-select-option
                                v-if="categoryList"
                                v-for="item in categoryList"
                                :value="item.Id"
                        >{{ item.title }}
                        </a-select-option
                        >
                    </a-select>
                </div>
                <div class="row">
                    <span class="row-title">状态</span>
                    <a-select class="row-input" v-model="Status">
                        <a-select-option value="1">
                            显示
                        </a-select-option>
                        <a-select-option value="0">
                            隐藏
                        </a-select-option>
                    </a-select>
                </div>
                <div class="row">
                    <span class="row-title">权限范围</span>
                    <a-select class="row-input" v-model="Range">
                        <a-select-option value="1">
                            会员
                        </a-select-option>
                        <a-select-option value="2">
                            非会员
                        </a-select-option>
                        <a-select-option value="0">
                            不限
                        </a-select-option>
                    </a-select>
                </div>
                <div class="row">
                    <span class="row-title">会员绑定</span>
                    <a-select class="row-input" v-model="IsCheck">
                        <a-select-option value="1">
                            是
                        </a-select-option>
                        <a-select-option value="0">
                            否
                        </a-select-option>
                    </a-select>
                </div>

                <div class="row">
                    <span class="row-title">内部可见</span>
                    <a-select class="row-input" v-model="System">
                        <a-select-option value="1">
                            是
                        </a-select-option>
                        <a-select-option value="0">
                            否
                        </a-select-option>
                    </a-select>
                </div>
                <div class="row">
                    <span class="row-title">时间</span>
                    <a-range-picker
                            class="row-input"
                            v-if="StartDate"
                            :value="[
              moment(StartDate, dateFormat),
              moment(EndDate, dateFormat)
            ]"
                            format="YYYY-MM-DD"
                            :placeholder="['开始时间', '结束时间']"
                            allowClear
                            @change="ChangeTime"
                    />
                    <a-range-picker
                            class="row-input"
                            v-else
                            format="YYYY-MM-DD "
                            :placeholder="['开始时间', '结束时间']"
                            allowClear
                            @change="ChangeTime"
                    />
                </div>
                <div class="row">
                    <span class="row-title">排序</span>
                    <a-input-number class="row-input" v-model="Sort" :min="0"/>
                </div>
                <div class="row">
                    <span class="row-title">备注</span>
                    <a-textarea
                            class="row-input"
                            v-model="Remark"
                            :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                </div>
                <div class="row">
                    <span class="row-title">banner图</span>
                    <a-button class="row-input" style="position: relative;width: 100px">
                        上传Banner
                        <input
                                accept=".jpg,.png,.gif,.jpeg"
                                type="file"
                                class="upload-bth"
                                id="BannerImg"
                                @change="_upload($event, 'BannerImg')"
                        />
                    </a-button>
                    <div
                            class="images"
                            v-if="BannerImg"
                            v-viewer
                            style="position: relative;"
                    >
                        <a-popconfirm
                                style="cursor: pointer;"
                                title="你确定要删除嘛?"
                                @confirm="handleClose('BannerImg')"
                                okText="确定"
                                cancelText="取消"
                        >
                            <a-icon
                                    style="position: absolute;top: -15px;right: -10px"
                                    type="close-circle"
                            />
                        </a-popconfirm>
                        <img :src="BannerImg" alt=""/>
                    </div>
                </div>
                <div class="row">
                    <span class="row-title">icon</span>
                    <a-button class="row-input" style="position: relative;width: 100px">
                        上传icon
                        <input
                                accept=".jpg,.png,.gif,.jpeg"
                                type="file"
                                class="upload-bth"
                                id="Icon"
                                @change="_upload($event, 'Icon')"
                        />
                    </a-button>
                    <div class="images" v-if="Icon" v-viewer style="position: relative;">
                        <a-popconfirm
                                style="cursor: pointer;"
                                title="你确定要删除嘛?"
                                @confirm="handleClose('Icon')"
                                okText="确定"
                                cancelText="取消"
                        >
                            <a-icon
                                    style="position: absolute;top: -15px;right: -10px"
                                    type="close-circle"
                            />
                        </a-popconfirm>
                        <img :src="Icon" alt=""/>
                    </div>
                </div>
                <div class="row">
                    <span class="row-title">icon小图标</span>
                    <a-button class="row-input" style="position: relative;width: 100px"
                    >上传小icon
                        <input
                                accept=".jpg,.png,.gif,.jpeg"
                                type="file"
                                class="upload-bth"
                                id="IconSmall"
                                @change="_upload($event, 'IconSmall')"
                        />
                    </a-button>
                    <div
                            class="images"
                            v-if="IconSmall"
                            v-viewer
                            style="position: relative;"
                    >
                        <a-popconfirm
                                style="cursor: pointer;"
                                title="你确定要删除嘛?"
                                @confirm="handleClose('IconSmall')"
                                okText="确定"
                                cancelText="取消"
                        >
                            <a-icon
                                    style="position: absolute;top: -15px;right: -10px"
                                    type="close-circle"
                            />
                        </a-popconfirm>
                        <img :src="IconSmall" alt=""/>
                    </div>
                </div>

                <div class="row">
                    <span class="row-title">类型</span>
                    <!--@change="_category"-->
                    <a-select
                            class="row-input"
                            :default-value="Category"
                            placeholder="请选择类型"
                            v-model="Category"
                            @change="_category"
                    >
                        <a-select-option value="h5">
                            活动h5页面
                        </a-select-option>
                        <a-select-option value="mini">
                            小程序
                        </a-select-option>
                        <a-select-option value="img">
                            图片
                        </a-select-option>
                    </a-select>
                </div>
                <!--v-if="Category == 'mini'"-->
                <div class="row">
                    <span class="row-title">小程序地址</span>
                    <a-input class="row-input" v-model="PageUrl"/>
                </div>
                <div class="row" v-if="Category == 'mini'">
                    <span class="row-title">小程序appid</span>
                    <a-input @blur="_appid" class="row-input" v-model="AppId"/>
                </div>
                <div class="row" v-if="Category == 'h5' || Category == 'img'">
                    <span class="row-title">web地址</span>
                    <a-input class="row-input" v-model="WebUrl"/>
                </div>
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="onClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_toAction">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <div v-show="spinning" style="position:fixed;left:50%;">
            <a-spin style="margin: 150px 0%;">
                <a-icon
                        slot="indicator"
                        type="loading"
                        :spinning="spinning"
                        style="font-size: 44px;color:red;"
                        spin
                />
            </a-spin>
        </div>
    </div>
</template>

<script>
    import 'viewerjs/dist/viewer.css'
    import Viewer from 'v-viewer'
    import Vue from 'vue'

    Vue.use(Viewer)
    import moment from 'moment'

    export default {
        name: 'MiniBanner',
        data() {
            return {
                data: '',
                Page: 1,
                Limit: 10,
                sTitle: '',
                sCategory: '',
                Title: '',
                total: '',
                sStatus: '1',
                sEndDate: '',
                sStartDate: '',
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                spinning: false,
                Id: '',
                Category: '',
                visible: false,
                dateFormat: 'YYYY-MM-DD',
                StartDate: '',
                EndDate: '',
                Sort: '',
                Remark: '',
                BannerImg: '',
                PageUrl: '',
                WebUrl: '',
                AppId: '',
                Status: '',
                Icon: '',
                Label: '',
                categoryList: '',
                sLabel: '',
                LabelId: '',
                LabelName: '',
                System: '',
                IconSmall: '',
                Range: '0',
                IsCheck: '1'
            }
        },
        created() {
            this._loadData()
            this._categoryList()
        },
        methods: {
            moment,
            _loadData(type) {
                this.spinning = true
                let self = this
                type == 'init' ? (self.Page = 1) : ''
                this.$axios.get(
                    1081,
                    {
                        Page: self.Page,
                        Limit: self.Limit,
                        Title: self.sTitle,
                        Category: self.sCategory,
                        LabelId: self.sLabel,
                        Status: self.sStatus,
                        EndDate: self.sEndDate,
                        StartDate: self.sStartDate
                    },
                    res => {
                        self.spinning = false
                        console.log(res)
                        if (res.data.code == 1) {
                            self.data = res.data.data
                            self.total = res.data.count
                        }
                    }
                )
            },
            _clear() {
                this.sTitle = ''
                this.sCategory = ''
                this.sLabel = ''
                this.sStatus = ''
                this.sEndDate = ''
                this.sStartDate = ''
                this._loadData('init')
            },
            _status(value) {
                this.sStatus = value
            },
            _search() {
                this._loadData('init')
            },
            ChangePage(value) {
                this.Page = value
                this._loadData()
            },
            _appid(val){
                if(this.AppId){
                   this.WebUrl ='';
                }
            },
            _category(val){
                if(val=='h5'){
                    this.PageUrl ='../../home/poster-newterm/poster-newterm';
                }else if(val=='mini'){
                    if(this.AppId){
                        this.WebUrl ='';
                    }
                }
            },
            _action(text, id) {
                if (id) {
                    this.AppId = text.AppId
                    this.BannerImg = text.BannerImg
                    this.Category = text.Category + ''
                    this.EndDate = text.EndDate
                    this.Icon = text.Icon
                    this.LabelId = text.LabelId
                    this.LabelName = text.LabelName
                    this.PageUrl = text.PageUrl
                    this.Remark = text.Remark
                    this.Sort = text.Sort
                    this.StartDate = text.StartDate
                    this.Status = text.Status + ''
                    this.Title = text.Title
                    this.WebUrl = text.WebUrl
                    this.System = text.System + ''
                    this.IconSmall = text.IconSmall
                    this.Range = text.Range + ''
                    this.IsCheck = text.IsCheck + ''
                    this.Id = id
                }
                this.visible = true
            },
            handleClose(name) {
                if (name == 'BannerImg') {
                    this.BannerImg = ''
                } else if (name == 'Icon') {
                    this.Icon = ''
                } else if (name == 'IconSmall') {
                    this.IconSmall = ''
                }
            },
            _del(id) {
                this.spinning = true
                this.$axios.get(
                    1082,
                    {
                        Id: id
                    },
                    res => {
                        this.spinning = false
                        if (res.data.code == 1) {
                            this._loadData()
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            onClose() {
                this.AppId = ''
                this.BannerImg = ''
                this.Category = ''
                this.EndDate = ''
                this.Icon = ''
                this.LabelId = ''
                this.LabelName = ''
                this.PageUrl = ''
                this.Remark = ''
                this.Sort = ''
                this.StartDate = ''
                this.Status = ''
                this.Title = ''
                this.WebUrl = ''
                this.Id = ''
                this.IconSmall = ''
                this.System = ''
                this.Range = '0'
                this.IsCheck = '1'
                this.visible = false
            },
            handleChange(value) {
                console.log(value)
                this.LabelName = value.label
                for (let i = 0; i < this.categoryList.length; i++) {
                    if (this.categoryList[i].Id == value) {
                        this.LabelName = this.categoryList[i].title
                    }
                }
            },
            _toAction() {
                let self = this
                if (!this.Title) {
                    this.$message.error('请输入标题')
                    return false
                }
                if (!this.LabelId) {
                    this.$message.error('请选择分类')
                    return false
                }
                if (this.Category) {
                    if (this.Category == 'mini') {
                        if (!this.PageUrl) {
                            this.$message.error('请输入小程序地址')
                            return false
                        }
                    } else if (this.Category == 'h5' || this.Category == 'img') {
                        if (this.WebUrl) {
                            if (this.WebUrl.indexOf('https://q.qitianzhen.cn') < 0) {
                                this.$message.error(
                                    '请输入正确的web地址,开头为https://q.qitianzhen.cn'
                                )
                                return false
                            }
                        }
                    }
                } else {
                    this.$message.error('请选择类型')
                    return false
                }
                this.spinning = true
                this.$axios.get(
                    1083,
                    {
                        Id: self.Id,
                        Title: self.Title,
                        Category: self.Category,
                        BannerImg: self.BannerImg,
                        PageUrl: self.PageUrl,
                        WebUrl: self.WebUrl,
                        AppId: self.AppId,
                        StartDate: self.StartDate,
                        EndDate: self.EndDate,
                        Status: self.Status,
                        Icon: self.Icon,
                        Sort: self.Sort,
                        Remark: self.Remark,
                        uid: self.userinfo.uid,
                        username: self.userinfo.name,
                        LabelId: self.LabelId,
                        LabelName: self.LabelName,
                        System: self.System,
                        IconSmall: self.IconSmall,
                        Range: self.Range,
                        IsCheck: self.IsCheck
                    },
                    res => {
                        this.spinning = false
                        if (res.data.code == 1) {
                            self._loadData()
                            self.onClose()
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            ChangeTime(time, timeString) {
                this.StartDate = timeString[0]
                this.EndDate = timeString[1]
            },
            ChangeSearchTime(time, timeString) {
                this.sStartDate = timeString[0]
                this.sEndDate = timeString[1]
                console.log(this.sStartDate)
                console.log(this.sEndDate)
            },
            _upload(e, name) {
                let self = this
                this.spinning = true
                let files = document.getElementById(name).files
                this.__uploadFile__(files).then(res => {
                    console.log(res)
                    self.spinning = false
                    if (res.length > 0) {
                        let url = res[0]['FileUrl']
                        if (name == 'BannerImg') {
                            self.BannerImg = url
                        }
                        if (name == 'Icon') {
                            self.Icon = url
                        }
                        if (name == 'IconSmall') {
                            self.IconSmall = url
                        }
                        self.$message.success('上传成功')
                    } else {
                        this.$message.error('上传失败!')
                    }
                })
            },
            _categoryList() {
                let self = this
                this.$axios.get(
                    1200,
                    {
                        label: 'Mini'
                    },
                    res => {
                        self.categoryList = res.data.data
                    }
                )
            },
            _changeCategory(value) {
                this.sCategory = value
            },
            _changeLabel(value) {
                this.sLabel = value
            }
        }
    }
</script>

<style scoped lang="less">
    .MiniBanner {
        background: #ffffff;
        margin-top: 15px;
        min-height: 800px;
        padding: 15px;
    }

    .search {
        margin-bottom: 20px;
        .search-group {
            display: inline-block;
            .search-label {
                margin-right: 5px;
            }
            .search-style {
                display: inline-block;
                width: 120px;
                margin-right: 15px;
            }
        }
    }

    .row {
        display: flex;
        margin-bottom: 20px;
        .row-title {
            display: block;
            width: 80px;
            height: 32px;
            line-height: 32px;
        }
        .row-input {
            /*flex:1;*/
            display: block;
            width: 320px;
        }
    }

    .upload-bth {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .images {
        max-width: 80px;
        max-height: 32px;
        margin-left: 15px;
        cursor: pointer;
        img {
            // width: 100%;
            // height: 100%;
            max-width: 80px;
            max-height: 32px;
        }
    }
</style>
